<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Đối soát VBee</h6>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Ngày bắt đầu(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày kết thúc(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Số điện thoại">
                <b-form-input v-model="filter.phone"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select :options="status" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Nguồn">
                <b-form-select :options="source" v-model="filter.source"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                  <b-button type="button" variant="success" @click="showModal()"><i class="fa fa-download"></i> Xuất Excel</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <b-tabs content-class="mt-3">
            <b-tab title="Danh sách" active>
              <summary-table v-if="items" :total="paginate.total" :perPage="paginate.limit" :currentPage="paginate.page" :totalPage="paginate.totalPage"></summary-table>
              <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
                <template #cell(index)="data">
                  {{(currentPage - 1) * 25 + (data.index + 1)}}
                </template>
                <template #cell(created_at)="data">
                  {{ moment(String(data.item.created_at)).subtract(7, 'h').format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #cell(updated_at)="data">
                  {{ moment(String(data.item.updated_at)).subtract(7, 'h').format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #cell(call_id)="data">
                  {{ getCallId(data.item.response_content) }}
                </template>
                <template #cell(duration)="data">
                  {{ getCallDuration(data.item.response_content) }}
                </template>
                <template #cell(status)="data">
                  {{ convertStatus(data.item.status) }}
                </template>
              </b-table>

              <div class="mt-3">
                <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination-nav>
              </div>
            </b-tab>
            <b-tab title="Tổng hợp">
              <b-table :items="groupItems" :fields = "groupFields" ref="table" striped hover responsive caption-top>
                <template #cell(total)="data">
                  {{ numberFormat(data.item.total) }}
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </b-card-group>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import SummaryTable from "@/view/pages/components/Summary.vue";
import router from "@/router";
import moment from "moment-timezone";

Vue.use(Notifications);

const ReconcileRepository = RepositoryFactory.get("reconcile");
export default {
  components: { AlertDialogue, ExportModal, SummaryTable },
  mixins: [Common],
  data() {
    return {
      exportId: "export_vbee_reconcile",
      items: null,
      groupItems: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: null,
        phone: null,
        source: null
      },
      status: [
        { value: "", text: "Tất cả" },
        { value: "0", text: "Đang chờ" },
        { value: 1, text: "Thành công" },
        { value: 2, text: "Thất bại" },
      ],
      source: [
        { value: "", text: "Tất cả" },
        { value: "WALLET", text: "WALLET" },
        { value: "SOUNDBOX", text: "SOUND BOX" },
      ],
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "provider",
          label: "Provider",
        },
        {
          key: "params",
          label: "Params",
        },
        {
          key: "call_id",
          label: "Call ID",
        },
        {
          key: "duration",
          label: "Thời gian cuộc gọi",
        },
        {
          key: "response_code",
          label: "Response Code",
        },
        {
          key: "phone",
          label: "SĐT",
        },
        {
          key: "source",
          label: "Nguồn",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
        {
          key: "created_at",
          label: "Ngày tạo",
        },
        {
          key: "updated_at",
          label: "Ngày cập nhật",
        },
      ],
      groupFields: [
        {
          key: "day",
          label: "Ngày",
        },
        {
          key: "total",
          label: "Record",
        },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát VBee", route: "vbee" },
    ]);
  },
  methods: {
    moment,
    search() {
      this.filter.page = 1;
      router
        .push({ path: "/reconcile/vbee", query: this.filter })
        .catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getTransactions();
      this.getByGroup();
    },
    async getTransactions() {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await ReconcileRepository.getVbeeTransactions(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      body = body.data;
      this.items = body.data.data;
      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
    },
    async getByGroup() {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await ReconcileRepository.getVbeeBygroup(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.groupItems = body.data.data;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description
      this.$bvModal.hide(this.exportId);

      let response = await ReconcileRepository.exportVbeeTransactions(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    getMcc(extraData) {
      if (!extraData) {
        return "";
      }

      let data = JSON.parse(extraData);

      if (!data.extra_data) {
        return "";
      }

      return data.extra_data.mccCode ? data.extra_data.mccCode : "";
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    getCallId(data)
    {
      if (!data) {
        return "---";
      }

      let response = JSON.parse(data);

      if (!response.response_ipn) {
        return "---";
      }

      if (!response.response_ipn.data) {
        return "---";
      }

      if (!response.response_ipn.data.call_id) {
        return "---";
      }

      return response.response_ipn.data.call_id;
    },
    getCallDuration(data)
    {
      if (!data) {
        return "---";
      }

      let response = JSON.parse(data);

      if (!response.response_ipn) {
        return "---";
      }

      if (!response.response_ipn.data) {
        return "---";
      }

      if (!response.response_ipn.data.duration) {
        return "---";
      }

      return response.response_ipn.data.duration + "s";
    },

    convertStatus(status) {
      if (status === 0) {
        return "Đang chờ";
      }

      if (status === 1) {
        return "Thành công";
      }

      return "Thất bại";
    }
  },
  created() {
    this.getTransactions();
    this.getByGroup();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getTransactions();
      }
    },
  },
};
</script>
